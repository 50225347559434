body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  color: #df3ca6;
}

.navbar-brand img {
  width: 30px;
  margin-right: 10px;
}

.btn {
  font-size: 15px;
}

.btn-primary,
.bg-primary {
  background-color: #df3ca6 !important;
  border: none;
}

.btn-primary:hover {
  background-color: #df3ca6;
  opacity: 0.9;
}

.btn-secondary {
  background-color: #7430f9;
}

.btn-secondary:hover {
  background-color: #7430f9;
  opacity: 0.9;
}

.icon {
  margin-right: 5px;
}